import { Behaviour } from "../base/config_sdk_behaviour";

export const BehaviourAdoptSiteSpecificBehaviours: Behaviour = {
  config: {
    id: "adopt_site_specific_behaviours",
    name: "Adopt Site Specific Behaviours",
    description: "Behaviour to adopt site specific device behaviours, will only adopt the closest site",
    parameters: [
      {
        id: "radius_from_beacons_meters",
        name: "Radius From Beacons (meters)",
        description: "The radius from the beacons to adopt the behaviours",
        required: true,
        type: "numberInt"
      },
    ],
    screens: [],
    canLinkTo: ["device"]
  },
  device: {
    tick: async (context) => {
      // Extract required context objects
      const { organization, device, behaviour, vigil } = context;

      // Get configured radius parameter
      const radiusFromBeaconsMeters = behaviour.getParameterValue<number>("radius_from_beacons_meters")!

      // Exit if device has no location
      const deviceLocation = await device.getLocation()
      if (!deviceLocation) return

      // Exit if organization has no beacons
      const beacons = await organization.getBeacons()
      if (beacons.length === 0) return

      // Get beacons within configured radius, sorted by distance
      const sortedBeacons = beacons
        .filter(beacon => vigil.distance(beacon, deviceLocation) <= radiusFromBeaconsMeters)
        .sort((a, b) => vigil.distance(a, deviceLocation) - vigil.distance(b, deviceLocation))
      if (sortedBeacons.length === 0) return

      // Process beacons in order of proximity
      for (const beacon of sortedBeacons) {
        // Skip beacons with no linked sites
        const sites = await beacon.getLinkedSites()
        if (sites.length === 0) continue

        // Process sites linked to current beacon
        for (const site of sites) {
          // Skip sites with no linked behaviours
          const siteDeviceBehaviours = await site.getLinkedDeviceBehaviours()
          if (siteDeviceBehaviours.length === 0) continue

          // Execute all behaviours for first valid site found
          for (const siteDeviceBehaviour of siteDeviceBehaviours) {
            await device.runBehaviour(siteDeviceBehaviour)
          }
        }
      }
    },
  },
  server: {
    tick: async (context) => {
      // Extract required context objects
      const { organization, device, behaviour, vigil } = context;

      // Get configured radius parameter
      const radiusFromBeaconsMeters = behaviour.getParameterValue<number>("radius_from_beacons_meters")!

      // Exit if device has no location
      const deviceLocation = await device.getLocation()
      if (!deviceLocation) return

      // Exit if organization has no beacons
      const beacons = await organization.getBeacons()
      if (beacons.length === 0) return

      // Get beacons within configured radius, sorted by distance
      const sortedBeacons = beacons
        .filter(beacon => vigil.distance(beacon, deviceLocation) <= radiusFromBeaconsMeters)
        .sort((a, b) => vigil.distance(a, deviceLocation) - vigil.distance(b, deviceLocation))
      if (sortedBeacons.length === 0) return

      // Process beacons in order of proximity
      for (const beacon of sortedBeacons) {
        // Skip beacons with no linked sites
        const sites = await beacon.getLinkedSites()
        if (sites.length === 0) continue

        // Process sites linked to current beacon
        for (const site of sites) {
          // Skip sites with no linked behaviours
          const siteDeviceBehaviours = await site.getLinkedDeviceBehaviours()
          if (siteDeviceBehaviours.length === 0) continue

          // Execute all behaviours for first valid site found
          for (const siteDeviceBehaviour of siteDeviceBehaviours) {
            await device.runBehaviour(siteDeviceBehaviour)
          }
        }
      }
    }
  }
}
