import { BehaviourParameter, Cron } from "vigil-config";
import { InputText } from "../../../components/input_text";
import { InputCron } from "../../../components/input_cron";
import { Checkbox } from "../../../components/checkbox";

export type BehaviourParameterWithValues = BehaviourParameter & { value: string | number | boolean | Cron | Array<any> };

export interface BehaviourParameterProps {
  parameter: BehaviourParameterWithValues
  stateBehaviourParameters: BehaviourParameterWithValues[];
  setBehaviourParameters: (parameters: BehaviourParameterWithValues[]) => void;
  onChange?: (parameter: BehaviourParameterWithValues) => void;
}

export function getDefaultBehaviourParameterValue(parameter: BehaviourParameter): string | number | boolean | Cron | Array<any> {
  if (parameter.type == 'string') return '';
  if (parameter.type == 'numberInt') return 1;
  if (parameter.type == 'numberFloat') return 1.0;
  if (parameter.type == 'boolean') return false;
  if (parameter.type == 'cron') return '0 12 * * *';
  return '';
}

export function BehaviourParameterStringInput(props: BehaviourParameterProps, className: string = '') {
  const value: string = props.parameter.value as string;
  return <div key={props.parameter.id} className={className}>
    <div className="font-bold text-sm">{props.parameter.name}</div>
    <div className="italic text-sm">{props.parameter.description}</div>
    <InputText className="w-44" key={props.parameter.id} inputType="text" labelText={''} value={value} onChange={(event) => {
      const parameterIndex = props.stateBehaviourParameters.findIndex((p) => p.id === props.parameter.id);
      const newBehaviourParameters = [...props.stateBehaviourParameters];
      newBehaviourParameters[parameterIndex] = { ...newBehaviourParameters[parameterIndex], value: event.target.value };
      props.setBehaviourParameters(newBehaviourParameters);
      if (props.onChange) props.onChange(newBehaviourParameters[parameterIndex]);
    }} errors={[]} />
  </div>
}

export function BehaviourParameterCronInput(props: BehaviourParameterProps, className: string = '') {
  const value: Cron = props.parameter.value as Cron;
  return <div key={props.parameter.id} className={className}>
    <div className="font-bold text-sm">{props.parameter.name}</div>
    <div className="italic text-sm mb-1">{props.parameter.description}</div>
    <InputCron key={props.parameter.id} cron={value} validationErrors={[]} onChangeCron={(cron) => {
      const parameterIndex = props.stateBehaviourParameters.findIndex((p) => p.id === props.parameter.id);
      const newBehaviourParameters = [...props.stateBehaviourParameters];
      newBehaviourParameters[parameterIndex] = { ...newBehaviourParameters[parameterIndex], value: cron };
      props.setBehaviourParameters(newBehaviourParameters);
      if (props.onChange) props.onChange(newBehaviourParameters[parameterIndex]);
    }} />
  </div>
}

export function BehaviourParameterNumberFloatInput(props: BehaviourParameterProps, className: string = '') {
  const value: number = props.parameter.value as number;
  return <div key={props.parameter.id} className={className}>
    <div className="font-bold text-sm">{props.parameter.name}</div>
    <div className="italic text-sm">{props.parameter.description}</div>
    <InputText className="w-44" key={props.parameter.id} inputType="numberFloat" labelText={''} value={value} onChange={(event) => {
      const parameterIndex = props.stateBehaviourParameters.findIndex((p) => p.id === props.parameter.id);
      const newBehaviourParameters = [...props.stateBehaviourParameters];
      newBehaviourParameters[parameterIndex] = { ...newBehaviourParameters[parameterIndex], value: parseFloat(event.target.value) };
      props.setBehaviourParameters(newBehaviourParameters);
      if (props.onChange) props.onChange(newBehaviourParameters[parameterIndex]);
    }} errors={[]} />
  </div>
}

export function BehaviourParameterNumberIntInput(props: BehaviourParameterProps, className: string = '') {
  const value: number = props.parameter.value as number;
  return <div key={props.parameter.id} className={className}>
    <div className="font-bold text-sm">{props.parameter.name}</div>
    <div className="italic text-sm">{props.parameter.description}</div>
    <InputText className="w-44" key={props.parameter.id} inputType="numberInt" labelText={''} value={value} onChange={(event) => {
      const parameterIndex = props.stateBehaviourParameters.findIndex((p) => p.id === props.parameter.id);
      const newBehaviourParameters = [...props.stateBehaviourParameters];
      newBehaviourParameters[parameterIndex] = { ...newBehaviourParameters[parameterIndex], value: parseInt(event.target.value) };
      props.setBehaviourParameters(newBehaviourParameters);
      if (props.onChange) props.onChange(newBehaviourParameters[parameterIndex]);
    }} errors={[]} />
  </div>
}

export function BehaviourParameterBooleanInput(props: BehaviourParameterProps, className: string = '') {
  const value: boolean = props.parameter.value as boolean;
  return <Checkbox key={props.parameter.id} label={props.parameter.name} checked={value} toggle={(checked) => {
    const parameterIndex = props.stateBehaviourParameters.findIndex((p) => p.id === props.parameter.id);
    const newBehaviourParameters = [...props.stateBehaviourParameters];
    newBehaviourParameters[parameterIndex] = { ...newBehaviourParameters[parameterIndex], value: checked };
    props.setBehaviourParameters(newBehaviourParameters);
    if (props.onChange) props.onChange(newBehaviourParameters[parameterIndex]);
  }} />
}
