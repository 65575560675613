import { ConfigDefault } from "./main";

export * from "./base/main";
export * from "./base/sdk_vigil";
export * from "./configs/behaviour_sign_in";
export * from "./configs/behaviour_sign_out";
export * from "./configs/config_default";

export const VIGIL_BEHAVIOURS = [
  ...ConfigDefault.behaviours
]