import React, { useContext, useState } from 'react';
import { useModal } from '../../hooks/use_modal';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { InputButton } from '../../components/input_button';
import { IconPencilSquareSolid, IconTrashSolid } from '../../components/icons';
import { ROUTES } from '../../router/routes';
import { IDeviceBehaviourSelect } from 'vigil-datamodel';
import { InputText } from '../../components/input_text';
import { VALIDATORS, validate } from '../../validation';
import { VIGIL_BEHAVIOURS } from 'vigil-config';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { ContextOrganization } from '../../providers/provider_organization';
import { BehaviourParameter, Cron } from 'vigil-config';
import { ModalDeleteMany } from './modal_delete_many';
import { ModalUpdateOne } from './modal_update_one';
import { BehaviourParameterStringInput, BehaviourParameterCronInput, BehaviourParameterNumberIntInput, BehaviourParameterNumberFloatInput, BehaviourParameterBooleanInput } from './func_device_behaviour/behaviour_inputs';

interface ScreenHomeDeviceBehaviourOverviewProps { }

type BehaviourParameterWithValues = BehaviourParameter & { value: string | number | boolean | Cron | Array<any> };

export const ScreenHomeDeviceBehaviourOverview: React.FC<ScreenHomeDeviceBehaviourOverviewProps> = (props) => {
  const { behaviourParent, fetchBehaviourParent } = useOutletContext<{
    behaviourParent: IDeviceBehaviourSelect,
    fetchBehaviourParent: () => void
  }>();
  const behaviour = VIGIL_BEHAVIOURS.find(b => b.config.id === behaviourParent.id);

  const vigil = useContext(ContextVigilClient);
  const organization = useContext(ContextOrganization);
  const navigate = useNavigate();

  const [stateBehaviourModify, setBehaviourModify] = useState<IDeviceBehaviourSelect>(behaviourParent);
  const [stateBehaviourParameters, setBehaviourParameters] = useState<BehaviourParameterWithValues[]>(
    behaviour?.config.parameters.map((parameter) => {
      const currentValue = behaviourParent.parameterValues[parameter.id];
      return { ...parameter, value: currentValue };
    }) ?? []
  );

  const { isOpen: isOpenModalDeleteBehaviour, toggle: toggleModalDeleteBehaviour } = useModal();
  const { isOpen: isOpenModalUpdateBehaviour, toggle: toggleModalUpdateBehaviour } = useModal();

  function onChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setBehaviourModify({
      ...stateBehaviourModify,
      name: event.target.value
    });
  }

  /* Validation */
  function validateName() {
    if (!stateBehaviourModify.name) return [];
    return validate(stateBehaviourModify.name, [VALIDATORS.length('Behaviour name', 2, 30)]);
  }

  function validateForm() {
    const required = [];
    if (JSON.stringify(stateBehaviourModify) === JSON.stringify(behaviourParent)) {
      required.push('No changes detected')
    }
    return [
      ...required,
      ...validateName(),
    ];
  }

  function onChangeParameter(parameter: BehaviourParameterWithValues) {
    setBehaviourModify({
      ...stateBehaviourModify,
      parameterValues: {
        ...stateBehaviourModify.parameterValues,
        [parameter.id]: parameter.value.toString()
      }
    });
  }

  async function _deleteBehaviour() {
    if (!organization.data) return;
    await vigil.functions.deleteDeviceBehaviours({
      uuidOrganization: organization.data.uuid,
      uuids: [behaviourParent.uuid]
    })
  }

  async function _updateBehaviour() {
    if (!organization.data) throw new Error('Organization not found');
    await vigil.functions.updateDeviceBehaviour({
      uuidOrganization: organization.data.uuid,
      uuid: stateBehaviourModify.uuid,
      data: stateBehaviourModify
    });
  }

  return (
    <div className='py-2'>
      <ModalDeleteMany
        isOpen={isOpenModalDeleteBehaviour}
        toggle={toggleModalDeleteBehaviour}
        type='deviceBehaviours'
        data={[{ uuid: behaviourParent.uuid, label: behaviourParent.name }]}
        onSubmit={async () => navigate(ROUTES.ROUTE_HOME_DEVICES)}
        deleteCallback={_deleteBehaviour}
      />
      <ModalUpdateOne
        isOpen={isOpenModalUpdateBehaviour}
        toggle={toggleModalUpdateBehaviour}
        updateCallback={_updateBehaviour}
        onSubmit={async () => { fetchBehaviourParent(); }}
      />

      <div className='rounded-t-xl bg-base-300 p-2'>
        <div className='flex items-center'>
          <div className='font-semibold flex flex-grow'>Overview</div>
          <InputButton
            text='Save'
            before={<IconPencilSquareSolid className='h-5 mr-1' />}
            type='btn-primary'
            size='btn-sm'
            disabled={validateForm().length > 0}
            onClick={toggleModalUpdateBehaviour}
          />
          <div className='w-2'></div>
          <InputButton
            text='Delete'
            before={<IconTrashSolid className='h-5 mr-1' />}
            type='btn-error'
            size='btn-sm'
            loading={false}
            onClick={toggleModalDeleteBehaviour}
          />
        </div>
      </div>

      <div className='rounded-b-xl bg-base-200 p-2 flex-grow'>
        <div className="flex flex-wrap">
          <InputText
            className="w-60 mb-2 mr-4"
            labelText='Behaviour name'
            value={stateBehaviourModify.name}
            onChange={onChangeName}
            errors={validateName()}
          />
          <div className="w-60 mb-2 mr-4">
            <label className="label">
              <span className="label-text">Behaviour Type</span>
            </label>
            <input
              type="text"
              className="input input-bordered w-full"
              value={behaviour?.config.name || ''}
              disabled
            />
          </div>
        </div>

        {/* Display behaviour parameters */}
        <div className="mt-4">
          <div className="font-bold text-m mb-2">Description</div>
          <div className="text-sm mb-4">{behaviour?.config.description}</div>
          <div className="font-bold text-m mb-2">Parameters</div>
          <div className="overflow-y-auto">
            {stateBehaviourParameters.map((parameter) => {
              if (parameter.type === 'string') return BehaviourParameterStringInput({ parameter, stateBehaviourParameters, setBehaviourParameters, onChange: onChangeParameter }, 'bg-base-300 p-2 rounded-lg mb-2 mr-2');
              if (parameter.type === 'cron') return BehaviourParameterCronInput({ parameter, stateBehaviourParameters, setBehaviourParameters, onChange: onChangeParameter }, 'bg-base-300 p-2 rounded-lg mb-2 mr-2');
              if (parameter.type === 'numberInt') return BehaviourParameterNumberIntInput({ parameter, stateBehaviourParameters, setBehaviourParameters, onChange: onChangeParameter }, 'bg-base-300 p-2 rounded-lg mb-2 mr-2');
              if (parameter.type === 'numberFloat') return BehaviourParameterNumberFloatInput({ parameter, stateBehaviourParameters, setBehaviourParameters, onChange: onChangeParameter }, 'bg-base-300 p-2 rounded-lg mb-2 mr-2');
              if (parameter.type === 'boolean') return BehaviourParameterBooleanInput({ parameter, stateBehaviourParameters, setBehaviourParameters, onChange: onChangeParameter }, 'bg-base-300 p-2 rounded-lg mb-2 mr-2');
              return null;
            })}
          </div>
        </div>
      </div>
    </div>
  )
};
