declare global {
  interface Window {
    env?: Record<string, any>;
  }
}

class TTInjectionConfig {
  public injectionConfig = true;
  public postprocess;
  constructor(postprocess?: (value: any) => any) {
    this.postprocess = postprocess? postprocess : (value: any) => value;
  }
}

export class TTEnvironment {
  public static source: Record<string, any>;
  public static init(source: Record<string, any>) {
    this.source = source;
    const configObject = this as any;
    for(const key of Object.keys(configObject)) {
      console.log('Loading ENV variable : ' + key)

      const configValue = configObject[key] as TTInjectionConfig;
      if (typeof configValue !== 'object') {
        console.log(`${key} is not set to inject() and will be ignored. Not object.`)
        continue;
      }

      if (!configValue['injectionConfig']) {
        console.log(`${key} is not set to inject() and will be ignored. No key found.`)
        continue;
      }

      if (!this.source[key] && typeof this.source[key] !== 'boolean') {
        throw new Error("Env var not set: " + key)
      }
      
      configObject[key] = configValue.postprocess(this.source[key]);
    }
  }

  protected static inject(postprocess?: (value: any) => any) {
    return new TTInjectionConfig(postprocess) as any;
  }

}
