import { useContext, useState } from "react";
import { VALIDATORS, validate } from "../../../validation";
import { InputText } from "../../../components/input_text";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { ContextOrganization } from "../../../providers/provider_organization";
import { Modal, ModalProps } from "../../../components/modal";
import { StatusAlert } from "../../../components/status_alert";
import { InputSelect } from "../../../components/input_select";
import { IDeviceBehaviourSelect } from "vigil-datamodel";
import { Behaviour, VIGIL_BEHAVIOURS } from "vigil-config";
import { InputButton } from "../../../components/input_button";
import { BehaviourParameterWithValues, BehaviourParameterBooleanInput, BehaviourParameterCronInput, BehaviourParameterNumberFloatInput, BehaviourParameterNumberIntInput, BehaviourParameterStringInput, getDefaultBehaviourParameterValue } from "./behaviour_inputs";
import { useStateCallback } from "../../../hooks/use_state_callback";



interface ModalDeviceBehaviourCreateProps extends ModalProps {
  uuidDevice?: string;
  uuidSite?: string;
  onSubmit?: (deviceBehaviour: IDeviceBehaviourSelect) => Promise<void>;
}

export const ModalDeviceBehaviourCreate: React.FC<ModalDeviceBehaviourCreateProps> = (props) => {
  let allBehaviours = VIGIL_BEHAVIOURS;
  if (props.uuidDevice) allBehaviours = allBehaviours.filter(behaviour => behaviour.config.canLinkTo.includes('device'));
  if (props.uuidSite) allBehaviours = allBehaviours.filter(behaviour => behaviour.config.canLinkTo.includes('site'));
  
  const behaviourOptions = allBehaviours.map((behaviour) => ({ value: behaviour.config.id, label: behaviour.config.name }));

  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const [stateName, setName] = useState<string>('');
  const [stateBehaviour, setBehaviour] = useState<Behaviour>(allBehaviours[0]);
  const [stateBehaviourParameters, setBehaviourParameters] = useState<BehaviourParameterWithValues[]>(allBehaviours[0].config.parameters.map((parameter) => {
    return { ...parameter, value: getDefaultBehaviourParameterValue(parameter) }
  }));

  /* DB Functions */
  const createDeviceBehaviour = useStateCallback(async () => {
    if (!organization.data) return;
    const deviceBehaviour = await vigil.functions.createDeviceBehaviour({
      uuidOrganization: organization.data.uuid,
      data: {
        uuidOrganization: organization.data.uuid,
        name: stateName,
        id: stateBehaviour.config.id,
        parameterValues: Object.fromEntries(stateBehaviourParameters.map((parameter) =>
          [parameter.id, parameter.value.toString()]
        ))
      }
    });
    props.uuidDevice && await vigil.functions.linkDeviceBehavioursToDevices({ uuidOrganization: organization.data.uuid, deviceUuids: [props.uuidDevice], deviceBehaviourUuids: [deviceBehaviour.uuid] });
    props.uuidSite && await vigil.functions.linkDeviceBehavioursToSites({ uuidOrganization: organization.data.uuid, siteUuids: [props.uuidSite], deviceBehaviourUuids: [deviceBehaviour.uuid] });
    props.onSubmit && await props.onSubmit(deviceBehaviour);
    props.toggle();
    resetState();
  })

  /* Validation */
  function validateName() {
    if (!stateName) return [];
    return validate(stateName, [VALIDATORS.length('Device behaviour name', 2, 30)]);
  }

  function validateForm() {
    const required = [];
    if (!stateName) { required.push('Device behaviour name is required') }
    return [
      ...required,
      ...validateName(),
    ];
  }

  /* Functions */
  function resetState() {
    setName('');
    setBehaviour(allBehaviours[0]);
    setBehaviourParameters(allBehaviours[0].config.parameters.map((parameter) => {
      return { ...parameter, value: getDefaultBehaviourParameterValue(parameter) }
    }));
  }

  /* UI Updates */
  function onChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setName(event.target.value);
  }

  function onChangeBehaviour(event: React.ChangeEvent<HTMLSelectElement>) {
    const behaviour = allBehaviours.find(behaviour => behaviour.config.id === event.target.value);
    if (behaviour) {
      setBehaviour(behaviour);
      setBehaviourParameters(behaviour.config.parameters.map((parameter) => {
        return { ...parameter, value: getDefaultBehaviourParameterValue(parameter) }
      }));
    }
  }

  return (
    <Modal className='w-192' isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false}>
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <h3 className="font-bold text-lg pb-2">Create new Device Behaviour</h3>
      <div className="flex flex-wrap">
        <InputText className='w-60 mr-2' labelText='Name' value={stateName} onChange={onChangeName} errors={validateName()} > </InputText>
        <InputSelect className='w-60 mr-2' labelText='Behaviour ID' value={stateBehaviour.config.id} onChange={onChangeBehaviour} options={behaviourOptions} errors={[]} />
      </div>
      <div className="overflow-y-auto">
        <div className="font-bold text-m mb-2">Description</div>
        <div className="text-sm mb-4">{stateBehaviour.config.description}</div>
        <div className="font-bold text-m mb-2">Parameters</div>
        {stateBehaviourParameters.map((parameter) => {
          if (parameter.type == 'string') return BehaviourParameterStringInput({ parameter, stateBehaviourParameters, setBehaviourParameters }, 'bg-base-200 p-2 rounded-lg mb-2 mr-2')
          if (parameter.type == 'cron') return BehaviourParameterCronInput({ parameter, stateBehaviourParameters, setBehaviourParameters }, 'bg-base-200 p-2 rounded-lg mb-2 mr-2')
          if (parameter.type == 'numberInt') return BehaviourParameterNumberIntInput({ parameter, stateBehaviourParameters, setBehaviourParameters }, 'bg-base-200 p-2 rounded-lg mb-2 mr-2')
          if (parameter.type == 'numberFloat') return BehaviourParameterNumberFloatInput({ parameter, stateBehaviourParameters, setBehaviourParameters }, 'bg-base-200 p-2 rounded-lg mb-2 mr-2')
          if (parameter.type == 'boolean') return BehaviourParameterBooleanInput({ parameter, stateBehaviourParameters, setBehaviourParameters }, 'bg-base-200 p-2 rounded-lg mb-2 mr-2')
          return null
        })}
      </div>
      {createDeviceBehaviour.error && <StatusAlert message={createDeviceBehaviour.error} type={"alert-error"} />}
      <div className="flex justify-end pt-2">
        <InputButton text='Confirm' loading={createDeviceBehaviour.loading} disabled={validateForm().length > 0} type='btn-primary' onClick={async () => await createDeviceBehaviour.call()}></InputButton>
      </div>
    </Modal>
  )
}